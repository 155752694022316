import React, {Component} from 'react'
import queryString from 'querystring'

import Loader from "../../common/loader/loader";
import styles from './token.module.scss'
import PropTypes from "prop-types";

class Token extends Component {

    componentDidMount() {
        const credentials = queryString.parse(this.props.location.hash);

        this.props.newCredentials({
            ...credentials,
            id_token: credentials['#id_token']
        })
    }

    render() {
        return (
            <div className={`${styles.tokenContainer}`}>
                <Loader/>
            </div>
        )
    }
}

Token.propTypes = {
    newCredentials: PropTypes.func
};

export default Token
