import {fork} from 'redux-saga/effects'
import authSaga from './authSaga'
import documentsSaga from './documentsSaga'
import answersSaga from './answersSaga'
import editorSaga from './editorSaga'
import reviewSaga from './reviewSaga'
import finaliseSaga from './finaliseSaga'
import templateSaga from './templateSaga'

export default function* rootSaga() {
    yield [
        fork(authSaga),
        fork(documentsSaga),
        fork(answersSaga),
        fork(editorSaga),
        fork(reviewSaga),
        fork(finaliseSaga),
        fork(templateSaga)
    ]
}
