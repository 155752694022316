import {connect} from 'react-redux'

import ReviewDocument from './reviewDocument'
import {documentFinaliseRequest, reviewDocumentRequest} from '../../../actions/review'

const mapStateToProps = (state) => ({
    version:  state.review.version,
    title: state.review.title,
    markdown: state.review.markdown
})

const mapDispatchToProps = dispatch => ({
    reviewDocument: (id) => dispatch(reviewDocumentRequest(id)),
    documentFinaliseRequest: (id, version) => dispatch(documentFinaliseRequest(id, version))
})

const ReviewDocumentContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ReviewDocument)

export default ReviewDocumentContainer
