
import gql from 'graphql-tag'

export default gql`
query listTemplates {
  listTemplates {
    items {
      id
      description
      title
      icon
  	}
  }
}
`
