import gql from 'graphql-tag'

export default gql`
mutation updateAnswer($updateAnswer: UpdateAnswerInput!) {
    updateAnswer(input: $updateAnswer) {
        fieldName
        value
    }
}
`


