import React from 'react'
import ReactDOM from 'react-dom'

// Materialize
import 'material-icons/iconfont/material-icons.css'
import 'materialize-css/dist/css/materialize.css'

// Fonts
import 'typeface-roboto'
import 'typeface-ibm-plex-sans'

// Central Variables
import './style/main.scss'

// Configure Amplify
import './config'

import App from './components/app/app'
import registerServiceWorker from './registerServiceWorker'

ReactDOM.render(<App />, document.getElementById('root'))
registerServiceWorker()
