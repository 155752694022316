import {connect} from 'react-redux'

import FinalModel from './finalModel'
import {clearFinalModel} from '../../actions/final'

const mapStateToProps = (state) => ({
    state: state.final.state,
    id: state.final.id,
    title: state.final.title,
    wordUrl: state.final.download.wordUrl,
    pdfUrl: state.final.download.pdfUrl,
    markdownUrl: state.final.download.markdownUrl,
    thumbNailUrl: state.final.download.thumbNailUrl
})

const mapDispatchToProps = dispatch => ({
    clearFinalModel: () => dispatch(clearFinalModel())
})

const FinalModelContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(FinalModel)

export default FinalModelContainer
