import React, {Component} from 'react'
import Question from './questions/question'

class Section extends Component {
    render() {
        const {
            questions,
            answers,
            metaData,
            errors,
            updateAnswer,
            validateAnswerRequest,
        } = this.props

        return (
            <div>
                {questions && questions.map((question, idx) => {
                    const error = errors[question.fieldName]
                    const meta = metaData[question.fieldName]

                    return <Question key={idx}
                                     answers={answers}
                                     error={error}
                                     updateAnswer={updateAnswer}
                                     validateAnswerRequest={validateAnswerRequest}
                                     {...question}
                                     {...meta} />
                })}
            </div>
        )
    }
}

Section.propTypes = {
    // idx: PropTypes.number.isRequired,
    // title: PropTypes.string.isRequired,
    // isActive: PropTypes.bool
}


export default Section
