import React, {Component} from 'react'
import _ from 'lodash'
import dotProp from 'dot-prop-immutable'
import {SortableContainer, SortableElement, arrayMove, SortableHandle} from 'react-sortable-hoc'

import styles from './textCollection.module.scss'
import Button from '../../../common/button/button'

class TextCollection extends Component {

    getCurrentAnswers() {
        const {
            answers,
            fieldName
        } = this.props
        return _.get(answers, `${fieldName}`, [])
    }

    updateAnswerDispatch(answers) {
        const {
            fieldName
        } = this.props
        return this.props.updateAnswer(fieldName, answers)
    }

    onSortEnd = ({oldIndex, newIndex}) => {
        const updatedAnswer = arrayMove(this.getCurrentAnswers(), oldIndex, newIndex)
        return this.updateAnswerDispatch(updatedAnswer)
    }


    updateAnswer(event, indx) {
        const updatedAnswer = dotProp.set(this.getCurrentAnswers(), `${indx}`, event.target.value)
        return this.updateAnswerDispatch(updatedAnswer)
    }

    addNewAnswer() {
        const updatedAnswer = Array.from(this.getCurrentAnswers())
        updatedAnswer.push('')
        return this.updateAnswerDispatch(updatedAnswer)
    }

    removeAnswer(index) {
        const updatedAnswer = Array.from(this.getCurrentAnswers())
        updatedAnswer.splice(index, 1)
        return this.updateAnswerDispatch(updatedAnswer)
    }

    render() {
        const {
            maxLength
        } = this.props

        const currentAnswers = this.getCurrentAnswers()

        return <div className={`${styles.textCollection}`}>
            {currentAnswers &&
            <SortableList answers={currentAnswers}
                          maxLength={maxLength}
                          updateAnswer={(e, idx) => this.updateAnswer(e, idx)}
                          removeAnswer={(idx) => this.removeAnswer( idx)}
                          useDragHandle={true}
                          onSortEnd={this.onSortEnd}/>
            }
            <div className={`${styles.bottomMenu}`}>
                <Button name={`Add`}
                        onClick={() => this.addNewAnswer()}
                        className={`${styles.itemAdd}`}/>
            </div>
        </div>
    }
}

const SortableItem = SortableElement((params) =>
    <Item {...params} />
)

const SortableList = SortableContainer(({answers, updateAnswer, maxLength, removeAnswer}) => {
    return (
        // sortIndex is required becaused HoC won't pass index down
        <ul>
            {answers.map((answer, index) => (
                <SortableItem key={index}
                              index={index}
                              sortIndex={index}
                              updateAnswer={updateAnswer}
                              removeAnswer={removeAnswer}
                              maxLength={maxLength}
                              answer={answer}/>
            ))}
        </ul>
    )
})


const DragHandle = SortableHandle(() => <i className={`material-icons ${styles.icon} ${styles.dragIcon}`}>reorder</i>) // This can be any component you want


class Item extends Component {
    render() {
        const {
            answer,
            maxLength,
            sortIndex,
            removeAnswer,
            updateAnswer
        } = this.props

        return <li className={`${styles.item}`} draggable>
            <div className={``}>
                <i className={`material-icons`}>subdirectory_arrow_right</i>
            </div>
            <div className={`${styles.textInput}`}>
                <input
                    value={answer}
                    onChange={e => updateAnswer(e, sortIndex)}
                    maxLength={maxLength}/>
            </div>
            <div className="">
                <i className={`material-icons ${styles.icon} ${styles.deleteIcon}`} onClick={() => removeAnswer(sortIndex)}>delete</i>
                <DragHandle/>
            </div>

        </li>
    }
}

export default TextCollection
