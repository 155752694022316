import Amplify from 'aws-amplify';

const config =  {
    'graphqlEndpoint': 'https://hfs2ilqvlbgzlfo6gbmrnrlf24.appsync-api.ap-southeast-2.amazonaws.com/graphql',
    'region': 'ap-southeast-2',
    'congitoURL': 'https://machine-legal.auth.ap-southeast-2.amazoncognito.com',
    'userPoolWebClientId': '11pcla6j3i8nj0aj3ncnd2c9ke'
}

Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        identityPoolId: 'ap-southeast-2_ifb67UGnX',
        region: config.region,
        userPoolWebClientId: config.userPoolWebClientId,
    }
});


export default config
