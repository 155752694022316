import React, {Component} from 'react'

import 'materialize-css/extras/noUiSlider/nouislider.css'
import noUiSlider from 'materialize-css/extras/noUiSlider/nouislider.min'

import style from './slider.module.scss'
import _ from 'lodash'

class SliderQuestion extends Component {

    componentDidMount() {
        const {
            options
        } = this.props;

        const range_all_sliders = {
            'min': [0,1],
            'max': [options.length - 1, 1]
        }

        this.uiSlider = noUiSlider.create(this.slider, {
            range: range_all_sliders,
            start: this.getCurrentAnswerIndx(),
            tooltips: false,
            pips: {
                mode: 'steps',
                density: 100,
                stepped: true,
                filter: () => 1,
                format: {
                    to: (val) => options[val].label
                }
            },
        })

        this.uiSlider.on('update', (value) => this.updateSlider(value))
    }

    getCurrentAnswerIndx() {
        const {
            answers,
            fieldName,
            options
        } = this.props;

        const currentAnswer = _.get(answers, `${fieldName}.[0]`);
        if(_.isNil(currentAnswer) || _.isNaN(currentAnswer)) {
            return 1
        } else {
            return _.findIndex(options,["value", currentAnswer])
        }
    }

    componentWillUnmount() {
        if (this.uiSlider) {
            this.uiSlider.off()
        }
    }

    updateSlider(value) {
        const {
            fieldName,
            options
        } = this.props

        const textValue = options[Math.floor(value)].value
        return this.props.updateAnswer(fieldName, [textValue])
    }

    render() {

        return <div>
            <div className={`switch ${style.slider}`}>
                <div ref={(slider) => this.slider = slider}/>
            </div>
        </div>
    }

}

export default SliderQuestion
