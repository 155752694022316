
import gql from 'graphql-tag'

export default gql`
query($input: GenerateDocumentInput!) {
    generateDocument(input: $input) {
        id
        title
        markdown
        version
    }
}
`


