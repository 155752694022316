import React, {Component} from 'react'

import styles from './tabMenu.module.scss'

class TabMenu extends Component {
    render() {
        return (
            <div className={`${styles.tabWrapper}`}>
                <ul className={`${styles.tabs} `}>
                    {this.props.children}
                </ul>
            </div>
        )
    }
}

export default TabMenu
