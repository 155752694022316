// import '../sections/Section.css';
import React, {Component} from 'react'
import _ from 'lodash'

class TextQuestion extends Component {

    updateQuestion(event, fieldName) {
        return this.props.updateAnswer(fieldName, [event.target.value])
    }

    handleBlur(event, fieldName) {
        return this.props.validateAnswerRequest(fieldName, [event.target.value]);
    }

    render() {
        const {
            fieldName,
            placeholder,
            maxLength,
            type,
            error
        } = this.props
        // let question = this.props.question;
        let answers = this.props.answers

        const currentAnswer = _.get(answers, `${fieldName}[0]`,'')
        const invalidClass = (_.isEmpty(error)) ? '' : 'invalid'
        const validClass = (!_.isEmpty(currentAnswer) && _.isEmpty(error)) ? 'valid' : ''

        return <input placeholder={placeholder} id={fieldName} type={type}
                      className={`${invalidClass} ${validClass}`}
                      onBlur={(event) => this.handleBlur(event, fieldName)}
                      onChange={(event) => this.updateQuestion(event, fieldName)}
                      value={currentAnswer}
                      maxLength={maxLength}
        />
    }
}

export default TextQuestion
