import React, {Component} from 'react'

import styles from './finalModel.module.scss'
import * as M from 'materialize-css'
import DownloadButton from '../common/downloadButton/downloadButton'
import {WORD_TYPE, MARKDOWN_TYPE, PDF_TYPE} from '../common/downloadButton/downloadButton'
import finalisedState from '../../consts/finalisedState'
import LoadingBar from '../common/loadingBar/loadingBar'

class FinalModel extends Component {

    componentDidMount() {
        this.model = M.Modal.init(this.modelDiv, {})
    }

    componentWillUnmount() {
        if (this.model) {
            this.model.destroy()
        }
        // Workaround for materialise defect
        document.body.style.overflow = '';
        this.props.clearFinalModel()
    }

    static download(link) {
        window.open(link);
    }

    render() {
        const {
            clearFinalModel,
            state,
            title,
            wordUrl,
            pdfUrl,
            markdownUrl,
            thumbNailUrl
        } = this.props

        let thumbNail = (thumbNailUrl) ? thumbNailUrl : '/docSample.png'

        return (
            <div>
                <div id="finalContainer"
                     className={`modal ${styles.model}`}
                     ref={div => {
                         this.modelDiv = div
                     }}>
                    <div className={`modal-content ${styles.modelContent}`}>


                        <div className={`${styles.header}`}>
                            <h2>{title}</h2>
                            <a href="#!" className="modal-close"> <i className="material-icons" onClick={()=>clearFinalModel()}>close</i></a>
                        </div>
                        {state !== finalisedState.completed && <p>Generating documements</p> }
                        {state !== finalisedState.completed && <LoadingBar /> }
                        {state === finalisedState.completed &&
                        <div>
                            <div className={`${styles.sampleImage}`}>
                                <img src={thumbNail} alt={'Doc Sample'}/>
                            </div>
                            <p>Download the finalised document in one of the following formats:</p>
                            <div className={`${styles.downloadContainer}`}>
                                <DownloadButton name={'Word'} type={WORD_TYPE} onClick={() => FinalModel.download(wordUrl)}/>
                                <DownloadButton name={'PDF'} type={PDF_TYPE} onClick={() => FinalModel.download(pdfUrl)}/>
                                <DownloadButton name={'Markdown'} type={MARKDOWN_TYPE} onClick={() => FinalModel.download(markdownUrl)}/>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        )
    }
}

FinalModel.propTypes = {
    // getDraftDocuments: PropTypes.func
}


export default FinalModel
