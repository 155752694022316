import {select, put, call} from 'redux-saga/effects'
import {
    invalidCredentials, tokenRefresh
} from '../actions'
import gqlService from '../services/gqlService'

const isAuthError = function (e) {
    return e.networkError && e.networkError.statusCode === 401
}

function* getIdToken() {
    console.log("gqlHandler>getIdToken")
    const tokens = yield select(state => state.auth.tokens)
    if (!tokens) {
        console.log("ID Token not found")
        yield put(tokenRefresh())
    }
    return tokens['id_token']
}

function* query(query, variables) {
    console.log("gqlHandler>query", query)
    const accessToken = yield call(getIdToken)
    try {
        let queryObject = {
            query,
            fetchPolicy: 'network-only',
        }

        if (variables) {
            queryObject = {
                ...queryObject,
                variables: variables
            }
        }

        return yield gqlService.query(queryObject, accessToken)
    } catch (e) {
        if (isAuthError(e)) {
            yield put(invalidCredentials())
        } else {
            throw e;
        }
    }
}

function* mutate(mutation, variables) {
    const accessToken = yield call(getIdToken)
    try {
        return yield call([gqlService, 'mutate'], {
            mutation: mutation,
            variables: variables
        }, accessToken)

    } catch (e) {
        if (isAuthError(e)) {
            yield put(invalidCredentials())
        } else {
            throw e;
        }
    }
}


export default {
    getIdToken,
    query,
    mutate
}
