import React, {Component} from 'react'
import PropTypes from 'prop-types'

import styles from './backButton.module.scss'
import {Link} from 'react-router-dom'

class BackButton extends Component {
    render() {
        const {
            documentId
        } = this.props
        if (documentId) {
            return (
                <Link to={`/editor/${documentId}/draft`}>
                    <button className={`${styles.button} ${this.props.className}`}>
                        <i className="material-icons">arrow_back</i></button>
                </Link>
            )
        }

        return (
            <Link to={`/documents/drafts`}>
                <button className={`${styles.button} ${this.props.className}`}>
                    <i className="material-icons">arrow_back</i></button>
            </Link>
        )
    }
}

BackButton.propTypes = {
    onClick: PropTypes.func.isRequired
}


export default BackButton

