
import gql from 'graphql-tag'

export default gql`
mutation($input: CreateDocumentInput!) {
createDocument(input:$input) {
   id
  }
}
`


