import AWSAppSyncClient from 'aws-appsync'
import {AUTH_TYPE} from 'aws-appsync/lib/link/auth-link'
import config from '../config'

const getClient = (accessToken) => new AWSAppSyncClient({
    url: config.graphqlEndpoint,
    region: config.region,
    disableOffline: true,
    auth: {
        type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
        jwtToken: async () => `${accessToken}`
    }
})

const gqlCall = (options, accessToken, operation) => getClient(accessToken).hydrated().then(client => operation(client))

const query = async (options, accessToken) => gqlCall(options, accessToken, (client) => client.query(options))
const mutate = async (options, accessToken) => gqlCall(options, accessToken, (client) => client.mutate(options))

export default {
    query,
    mutate
}
