import React, {Component} from 'react'

import styles from './modal.module.scss'
import * as M from 'materialize-css'
import PropTypes from 'prop-types'

export const Type = {
    Wide: 'Wide'
};

class Modal extends Component {

    componentDidMount() {
        this.model = M.Modal.init(this.modelDiv, {})
    }

    componentWillUnmount() {
        if (this.model) {
            this.model.destroy()
        }
        // Workaround for materialise defect
        document.body.style.overflow = '';
    }

    render() {
        const {
            id,
            title,
            type
        } = this.props;

        return <div id={id} className={`modal ${styles.model} ${type === Type.Wide ? styles.wide : ''}`}
                    ref={div => {
                        this.modelDiv = div
                    }}>
            <div className={`modal-content ${styles.modelContent}`}>
                <div className={`${styles.header}`}>
                    <h2>{title}</h2>
                    <a href="#!" className="modal-close"> <i className="material-icons">close</i></a>
                </div>
                {this.props.children}
            </div>
        </div>
    }
}

Modal.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
    // getDraftDocuments: PropTypes.func
}


export default Modal
