import React, {Component} from 'react'

import styles from './newDocButton.module.scss'
import PropTypes from 'prop-types'


class NewDocButton extends Component {

    render() {
        const {
            title,
            icon,
            onClick,
            onMouseOver,
            onMouseOut
        } = this.props;

        return (
            <div className={`${styles.button} `}
                 onClick={(e) => onClick(e)}
                 onMouseOut={(e)=>onMouseOut(e)}
                 onMouseOver={(e) => onMouseOver(e)}>
                <i className={`material-icons ${styles.icon}`}>{icon}</i>
                <span>{title}</span>
            </div>
        )
    }
}

NewDocButton.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    onMouseOver: PropTypes.func,
    onMouseOut: PropTypes.func,
    onClick: PropTypes.PropTypes.func.isRequired
};


export default NewDocButton
