import {connect} from 'react-redux'
import NewDocument from './newDocument'
import {getTemplatesRequest} from '../../../../actions/documents'
import {newDocumentRequest} from '../../../../actions'

const mapStateToProps = (state) => ({
    templates: state.documents.templates
})

const mapDispatchToProps = dispatch => ({
    // clearFinalModel: () => dispatch(clearFinalModel())
    getTemplates: ()=> dispatch(getTemplatesRequest()),
    newDocumentRequest: (id, title) => dispatch(newDocumentRequest(id, title))
})

const NewDocumentContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(NewDocument)

export default NewDocumentContainer
