import {call, takeLatest, takeEvery, put} from 'redux-saga/effects'
import jwtDecode from 'jwt-decode'

import authService from '../services/authService'
import history from '../services/history'

import {
    addCredentialsToState,
    INVALID_CREDENTIALS,
    invalidCredentials,
    NEW_CREDENTIALS,
    TOKEN_REFRESH,
    TOKEN_VALIDATE
} from '../actions'


const getExpiry = (idToken) => jwtDecode(idToken).exp

const hasTokenExpired = (idToken) => {
    const tokenExpiry = getExpiry(idToken)
    const currentTime = Math.floor((new Date()).getTime() / 1000)
    return tokenExpiry <= currentTime
}

function* tokenRefresh() {
    yield call(authService.signIn)
}

function* tokenValidate() {
    const persistedCredentials = authService.retrieveCredentials()
    if (persistedCredentials !== null) {
        const hasExpired = hasTokenExpired(persistedCredentials.id_token)
        if (hasExpired) {
            yield put(invalidCredentials())
        } else {
            yield put(addCredentialsToState(persistedCredentials))
        }
    } else {
        yield put(invalidCredentials())
    }
}

/*
    Inspired by this: https://blog.bitsrc.io/complex-app-logic-with-redux-and-redux-saga-write-an-authentication-monitor-2f5672303d7d
 */
function* newCredentials(action) {
    yield authService.persistCredentials(action.credentials)
    yield history.push('/')
}


function* authSaga() {
    yield takeEvery(TOKEN_VALIDATE, tokenValidate)
    yield takeLatest(TOKEN_REFRESH, tokenRefresh)
    yield takeLatest(INVALID_CREDENTIALS, tokenRefresh)
    yield takeLatest(NEW_CREDENTIALS, newCredentials)
}

export default authSaga
