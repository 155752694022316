import React, {Component} from 'react'
import moment from 'moment'
import M from 'materialize-css'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'

import styles from './documentItem.module.scss'

const dropDownMenuContainer = (props) => `dropDownBounding_${props.docId}`


class DocumentItem extends Component {
    componentDidMount() {

        this.dropDownDropdown = M.Dropdown.init(this.docBtn, {
            alignment: 'top',
            autoTrigger: true,
            coverTrigger: false,
            closeOnClick: true,
            constrainWidth: false,
            container: dropDownMenuContainer(this.props),
            // hover: truex
        })
    }

    componentWillUnmount() {
        if (this.dropDownDropdown) {
            this.dropDownDropdown.destroy()
        }
    }

    deleteDoc(e) {
        const {
            deleteDocument,
            docId,
        } = this.props

        e.preventDefault()
        deleteDocument(docId)
    }

    render() {
        const {
            editDocumentView,
            docId,
            modified,
            title,
            template
        } = this.props

        const parsedDate = moment(modified)

        const dropDownId = `dropDown_${docId}`
        const dropDownMenuClass = dropDownMenuContainer(this.props)

        return (
            <div className={`${styles.documentItem}`}>
                <div>
                    <h3><Link to={`/editor/${docId}/draft`} className={``}>{title}</Link></h3>
                </div>
                <div className={`${styles.documentMenu}`}>
                    <ul>
                        <li>Last edited {parsedDate.calendar()}</li>
                        <li>{template.title}</li>
                        {/*<li><span className={`${styles.status} ok`}>ok</span></li>*/}
                        <li><a href="#" data-target={dropDownId} ref={docBtn => this.docBtn = docBtn}
                               className={'dropdown-trigger'}>
                            <i className={`material-icons ${styles.materialIcons}`}>expand_more</i>
                        </a></li>
                    </ul>
                </div>

                <div className={dropDownMenuClass}>
                    <ul id={dropDownId} className={`${styles.dropMenu} dropdown-content`}>
                        <li><a href="#"
                               onClick={() => editDocumentView(docId)}>
                            <i className="material-icons">edit</i> Edit</a></li>
                        <li><a href="#"
                               onClick={(e) => this.deleteDoc(e)}
                        ><i className="material-icons">delete</i> Delete</a></li>
                    </ul>
                </div>
            </div>
        )
    }
}

DocumentItem.propTypes = {
    docId: PropTypes.string.isRequired,
    title: PropTypes.string,
    editDocumentView: PropTypes.func.isRequired,
    deleteDocument: PropTypes.func.isRequired
}

export default DocumentItem
