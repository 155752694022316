import React, {Component, Fragment} from 'react'

import styles from './newDocument.module.scss'
import NewDocButton from './newDocButton/newDocButton'
import LoadingBar from '../../../common/loadingBar/loadingBar'

class NewDocument extends Component {

    constructor(props) {
        super(props)
        this.state = {
            description: '',
            creating: false
        }
    }

    componentDidMount() {
        console.log('DocumentsList/newDocument')
        this.props.getTemplates()
    }

    onMouseOver(idx) {
        this.setState({
            description: this.props.templates[idx].description
        })
    }

    onMouseOut() {
        this.setState({
            description: ''
        })
    }

    onClick(template) {
        const {
            newDocumentRequest
        } = this.props

        this.setState({
            creating: true
        })

        newDocumentRequest(template.id, template.title)
    }

    render() {
        const {
            templates
        } = this.props

        const {
            creating,
            description
        } = this.state

        return (
            <div className={`${styles.documentList}`}>
                {templates && <Fragment>
                    {creating && <Fragment>
                        <LoadingBar />
                    </Fragment>}
                    {!creating && <Fragment>
                        <div className={`${styles.buttonContainer}`}>
                            {templates.map((template, i) =>
                                <NewDocButton title={template.title}
                                              icon={template.icon} key={i}
                                              onClick={() => this.onClick(template)}
                                              onMouseOut={() => this.onMouseOut(i)}
                                              onMouseOver={() => this.onMouseOver(i)}/>)}
                        </div>
                        <div className={`${styles.footer}`}>
                            <p>{description}</p>
                        </div>
                    </Fragment>}
                </Fragment>}
            </div>
        )
    }
}

NewDocument.propTypes = {
    // getDraftDocuments: PropTypes.func
}


export default NewDocument
