import {delay, takeEvery} from 'redux-saga'
import {select, put, cancel, fork, take,} from 'redux-saga/effects'
import {answerUpdate, updateAnswerSuccess} from '../actions/editor'
import apiGraphql from '../api/graphql'
import {GET_DOCUMENT_SUCCESS} from '../actions'
import gqlHandler from './gqlHandler'


const graphQLAnswersToObject = answers => {
    const combinedAnswers = answers.map(answer => ({[answer.fieldName]: answer.value}))
    return Object.assign({}, ...combinedAnswers)
}

function* updateAnswer(action) {

    yield delay(1500)
    const documentId = yield select(state => state.editor.documentId)

    // Filters out empty values, which Dynamo doesn't allow.
    const value = action.value.filter(val => val)

    const updateAnswer = yield gqlHandler.mutate(apiGraphql.mutations.updateAnswer, {
        updateAnswer: {
            documentId,
            fieldName: action.fieldName,
            value
        }
    })

    yield put(updateAnswerSuccess(updateAnswer))
}

function* updateWatcher() {
    let taskMap = {}
    while (true) {
        const action = yield take('UPDATE_ANSWER_REQUEST')
        const task = taskMap[action.fieldName]
        if (task) {
            yield cancel(task)
        }
        taskMap[action.fieldName] = yield fork(updateAnswer, action)
    }
}

function* answerRefresh(action) {
    console.log('answersSaga/answerRefresh')
    yield put(
        answerUpdate(graphQLAnswersToObject(action.document.answers)))
}


function* answersSaga() {
    yield fork(updateWatcher)
    yield takeEvery(GET_DOCUMENT_SUCCESS, answerRefresh)
}

export default answersSaga
