
export const GET_TEMPLATES_REQUEST = 'GET_TEMPLATES_REQUEST'
export const GET_TEMPLATES_SUCCESS = 'GET_TEMPLATES_SUCCESS'
export const GET_TEMPLATES_FAILURE = 'GET_TEMPLATES_FAILURE'

// Get Draft
const getTemplatesRequest = () => ({type: GET_TEMPLATES_REQUEST})
const getTemplatesSuccess = (templates) => ({type: GET_TEMPLATES_SUCCESS, templates})
const getTemplatesFailure = () => ({type: GET_TEMPLATES_FAILURE})


export {
    getTemplatesRequest,
    getTemplatesSuccess,
    getTemplatesFailure
}
