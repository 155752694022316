import {connect} from 'react-redux'
import Editor from './editor'
import {goToSection} from '../../actions/editor'
const mapStateToProps = (state, ownProps) => ({
    documentId: ownProps.documentId,
    sections: state.editor.sections,
    activeSection: state.editor.activeSection,
    sectionStatus: state.editor.sectionStatus
})

const mapDispatchToProps = (dispatch) => ({
    goToSection: (sectionIndx) => dispatch(goToSection(sectionIndx)),
})

const EditorContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Editor)

export default EditorContainer
