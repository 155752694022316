import {connect} from 'react-redux'
import Token from './token'
import {newCredentials} from "../../../actions";

// import Questions from './Questions'
// import {listAnswers, saveAnswer, updateAnswer} from '../actions'

const mapStateToProps = (state) => ({
    // questions: state.questions.answers
})

const mapDispatchToProps = dispatch => ({
    newCredentials: (credentials) => dispatch(newCredentials(credentials))
    // listAnswers: () => dispatch(listAnswers()),
    // saveAnswer: (question, answer) => dispatch(saveAnswer(question, answer)),
    // updateAnswerRequest: (question, answer) => dispatch(updateAnswer(question, answer))
})

const TokenContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Token)

export default TokenContainer
