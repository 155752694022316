
import gql from 'graphql-tag'

export default gql`
query listDocuments {
  listDocuments {
    items {
     id
     title
     modified
     template {
        title
     }
    },
    nextToken
  }
}
`
