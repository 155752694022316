import React, {Component} from 'react';
import _ from 'lodash';

import style from './radioQuestion.module.scss'

class RadioQuestion extends Component {

    updateQuestion(event) {
        const {
            fieldName,
        } = this.props;

        const targetAnswer = event.target.checked;
        return this.props.updateAnswer(fieldName, [`${targetAnswer}`])
    }

    changeSelection(event) {
        const {
            fieldName
        } = this.props
        return this.props.updateAnswer(fieldName, [event.target.value])
    }

    render() {
        const {
            answers,
            fieldName
        } = this.props;

        const currentAnswer = _.get(answers, `${fieldName}.[0]`);

        return <div className={`switch ${style.radio}`}>
            {this.props.options.map((option, idx) => {
                const isChecked = option.value === currentAnswer;

                return <p key={idx}>
                    <label>
                        <input name={`group_${fieldName}`} type="radio"
                               checked={isChecked} value={option.value}
                                onChange={(e) => this.changeSelection(e)}/>
                        <span className={`${style.label}`}>{option.label}</span>
                    </label>
                </p>
            })}
        </div>
    }
}

export default RadioQuestion;
