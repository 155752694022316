import React, {Component} from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import sanitize from "sanitize-filename"

import styles from './finalItem.module.scss'
import { ReactComponent as WordLogo } from './word-file.svg';
import { ReactComponent as PdfLogo } from './pdf-file.svg';
import { ReactComponent as MarkdownLogo } from './code.svg';


class FinalItem extends Component {

    friendlyName = (suffix) => `${sanitize(this.props.title)}.${suffix}`

    render() {
        const {
            docId,
            documentFinaliseRequest,
            version,
            startTime,
            title,
            download
        } = this.props

        const {
            markdownUrl,
            pdfUrl, wordUrl
        } = download;

        const parsedDate = moment(startTime)
        return (
            <div className={`${styles.documentItem}`}>
                <div className={`${styles.documentTitle}`}>
                    <h3>{title}</h3>
                </div>
                <div className={`${styles.documentSubtitle}`}>
                    <ul>
                        <li>Generated {parsedDate.calendar()}</li>
                    </ul>
                </div>
                <div className={`${styles.downloadMenu}`}>
                    {pdfUrl && <a href={pdfUrl} download={this.friendlyName('pdf')}><PdfLogo/></a>}
                    {wordUrl && <a href={wordUrl} download={this.friendlyName('docx')}><WordLogo /></a>}
                    {markdownUrl && <a href={markdownUrl} download={this.friendlyName('md')}><MarkdownLogo /></a>}
                </div>
            </div>
        )
    }
}


FinalItem.propTypes = {
    docId: PropTypes.string.isRequired,
    title: PropTypes.string
}

export default FinalItem
