import React, {Component} from 'react'
import M from 'materialize-css'

import styles from './avatar.module.scss'

class Avatar extends Component {

  componentDidMount() {
    this.profileBtnDropdown = M.Dropdown.init(this.profileBtn, {
      alignment: 'top',
      autoTrigger: true,
      coverTrigger: false,
      closeOnClick: true,
      constrainWidth: false,
      // hover: true
    })
  }

  componentWillUnmount() {
    if (this.profileBtnDropdown) {
      this.profileBtnDropdown.destroy()
    }
  }

  render() {
    return (
      <div className={`${styles.avatar}`}>
        <div>
          <a href="/team" className={`${styles.team}`}>Example Co.</a>
          <a href="#" data-target='profileMenu' ref={ref => this.profileBtn = ref}><img src='/avatar.jpg'
                                                                                        alt="SUPER AVATAR"/></a>
          <ul id='profileMenu' className={`${styles.profileMenu} dropdown-content`}>
            <li><a href="#!">Settings</a></li>
            <li><a href="#!">Sign Out</a></li>
          </ul>
        </div>
      </div>
    )
  }
}

export default Avatar
