import {takeLatest, put} from 'redux-saga/effects'
import _ from 'lodash'

import apiGraphql from '../api/graphql'
import gqlHandler from './gqlHandler'
import {
    GET_TEMPLATES_REQUEST,
    getTemplatesFailure,
    getTemplatesSuccess
} from '../actions/documents'

function* getTemplates() {
    console.log("templatesSaga/getTemplates")
    const queryData = yield gqlHandler.query(apiGraphql.queries.listTemplates)
    const templateList = _.get(queryData, 'data.listTemplates.items')

    if (templateList) {
        yield (put(getTemplatesSuccess(templateList)))
    } else {
        yield (put(getTemplatesFailure()))
    }
}

function* documentsSaga() {
    yield takeLatest(GET_TEMPLATES_REQUEST, getTemplates)
}

export default documentsSaga
