import {ADD_CREDENTIALS_TO_STATE, NEW_CREDENTIALS} from "../actions";

const auth = (state = {}, action) => {
    switch (action.type) {
        case NEW_CREDENTIALS:
            return {
                ...state,
                tokens: action.credentials
            }
        case ADD_CREDENTIALS_TO_STATE:
            return {
                ...state,
                tokens: action.credentials
            }
        default:
            return state
    }
}

export default auth
