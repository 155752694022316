
import gql from 'graphql-tag'

export default gql`
    mutation ($input: DeleteDocumentInput!) {
        deleteDocument(input: $input) {
          id
        }
  }
`


