import React, { Component } from 'react'

import styles from './footer.module.scss'

class Footer extends Component {
  render () {
    return <footer className={`${styles.footer} page-footer white`}>
      <div className={`${styles.footerContent} container right-align`}>
        <ul>
          <li><a href="/help"> Help </a></li>
          <li><a href="/status"> Status </a></li>
          <li><a href="/privacy"> Privacy </a></li>
          <li><a href="/terms"> Terms </a></li>
          <li><a href="/about"> About </a></li>
        </ul>
      </div>
    </footer>
  }
}

export default Footer
