import getDocuments from './queries/getDocuments'
import getDocument from './queries/getDocument'
import createDocument from './mutations/createDocument'
import updateAnswer from './mutations/updateAnswer'
import generateDocument from './queries/generateDocument'
import finaliseDocument from './mutations/finaliseDocument'
import getFinalisedDocument from './queries/getFinalisedDocument'
import getFinalisedDocuments from './queries/getFinalisedDocuments'
import listTemplates from './queries/listTemplates'
import deleteDocument from './mutations/deleteDocument'
import updateDocument from './mutations/updateDocument'

export default {
    queries: {
        getDocuments,
        generateDocument,
        getDocument,
        getFinalisedDocument,
        getFinalisedDocuments,
        listTemplates
    },
    mutations: {
        createDocument,
        deleteDocument,
        finaliseDocument,
        updateAnswer,
        updateDocument
    }
}
