import React, {Component} from 'react'
import {ReactComponent as Logo} from './logo.svg'
import { Link } from 'react-router-dom'

import styles from './header.module.scss'
import Avatar from './avatar/avatar'

class Header extends Component {
    render() {
        return (
            <nav className={`${styles.header}`}>
                <div className={`${styles.headerContainer} container`}>
                    <div className={`${styles.leftMenu}`}>
                        <Link  to='/' className={`${styles.logo}`}><Logo/></Link>
                    </div>
                    <div className={`${styles.rightMenu}`}>
                        <Avatar/>
                    </div>
                </div>
            </nav>
        )
    }
}

export default Header
