import React, {Component} from 'react'

import styles from './sectionStep.module.scss'
import PropTypes from 'prop-types'
import SectionContainer from './sections/section.container'
import Button from '../common/button/button'
import sectionStatusEnum from '../../consts/sectionStatus'
import ReviewSectionContainer from './sections/reviewSection.container'


class SectionStep extends Component {

    componentDidMount() {

    }

    static statusToClass(sectionStatus) {
        switch (sectionStatus) {
            case sectionStatusEnum.COMPLETE:
                return 'done'
            case sectionStatusEnum.IN_ERROR:
                return 'wrong'
            default:
                return ''
        }
    }

    sectionToRender() {
        const {
            idx,
            documentId,
            sectionType
        } = this.props

        if (sectionType === 'review') {
            return <ReviewSectionContainer documentId={documentId} />
        } else {
            return <SectionContainer idx={idx}/>
        }
    }

    render() {
        const {
            idx,
            title,
            sectionStatus,
            isActive,
            goToSection,
            totalSections
        } = this.props

        return (
            <li className={`step ${SectionStep.statusToClass(sectionStatus)}`}>
                <div onClick={() => goToSection(idx)}
                     className={`${styles.stepTitle} ${isActive ? 'active' : ''}`}>{title}</div>
                {isActive &&
                <div className="step-content">
                    {this.sectionToRender()}
                    <div className={`${styles.stepActions}`}>
                        {idx < (totalSections - 1) &&
                        <Button name={'Next'}
                                onClick={() => goToSection(idx + 1)}
                                inverted={true}
                        />
                        }
                    </div>
                </div>
                }
            </li>
        )
    }
}

SectionStep.propTypes = {
    idx: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    isActive: PropTypes.bool,
    goToSection: PropTypes.func
}


export default SectionStep
