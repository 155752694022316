import React, {Component} from 'react'
import PropTypes from 'prop-types';

import styles from './button.module.scss'

class Button extends Component {

  render() {
    return (
      <button className={`${styles.button}
       ${this.props.inverted?styles.inverted:''} ${this.props.className}`}
              onClick={()=>this.props.onClick()}
              data-target={this.props.dataTarget}
      >{this.props.name}</button>
    )
  }
}

Button.propTypes = {
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    inverted: PropTypes.bool,
    className: PropTypes.string,
    dataTarget: PropTypes.string
};


export default Button

