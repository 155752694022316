import React, {Component} from 'react'
import {createStore, applyMiddleware, combineReducers} from 'redux'
import createSagaMiddleware from 'redux-saga'

import {Provider} from 'react-redux'
import {Router, Route, Switch, Redirect} from 'react-router-dom'

import Header from '../header/header'
import Footer from '../footer/footer'
import reducers from '../../reducers'
import saga from '../../sagas'
import DocumentsListContainer from '../pages/documentsList/documentList.container'
import TokenContainer from '../pages/token/token.container'
import history from '../../services/history'
import DraftDocumentContainer from '../pages/draftDocument/draftDocument.container'
import ReviewDocumentContainer from '../pages/reviewDocument/reviewDocument.container'
import withAuth from '../common/auth/auth'

const sagaMiddleware = createSagaMiddleware()

/*
 Redux store
 */
const store = createStore(combineReducers({
    ...reducers
}), applyMiddleware(sagaMiddleware))
sagaMiddleware.run(saga)

const Routes = () => (
    <Switch>
            <Route path="/oauth2/token" component={TokenContainer}/>
            <Route path="/editor/:documentId/draft" component={withAuth(DraftDocumentContainer)}/>
            <Route path="/editor/:documentId/review" component={withAuth(ReviewDocumentContainer)}/>
            <Route path="/documents/:viewType" component={withAuth(DocumentsListContainer)}/>
            <Route exact path="/" >
                <Redirect to="/documents/drafts" />
            </Route>
    </Switch>
)

class AppContainer extends Component {

    render() {
        return (
            <Provider store={store}>
                <Router history={history}>
                    <div className="App">
                        <Header/>
                        <div className="container">
                            <div>
                                <Routes />
                            </div>
                        </div>
                        <Footer/>
                    </div>
                </Router>
            </Provider>
        )
    }
}


export default AppContainer
