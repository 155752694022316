import gql from 'graphql-tag'

export default gql`
    query listFinalisedDocuments {
        listFinalisedDocuments {
            items {
                id
                title
                startTime
                download {
                    wordUrl
                    pdfUrl
                    markdownUrl
                }
            }
        }
    }
`
