import React, {Component} from 'react'

import 'materialize-css/extras/noUiSlider/nouislider.css'
import noUiSlider from 'materialize-css/extras/noUiSlider/nouislider.min'

import style from './durationQuestion.module.scss'
import _ from 'lodash'

const getCurrentAnswer = (answers, fieldName) => {
    const currentAnswer = _.get(answers, `${fieldName}.[0]`);
    if(_.isNil(currentAnswer) || _.isNaN(currentAnswer)) {
        return 1
    } else {
        return currentAnswer
    }
}

class DurationQuestion extends Component {
    componentDidMount() {
        const range_all_sliders = {
            'min': [1, 1],
            '10%': [12, 12],
            'max': [144]
        }

        const currentAnswerString = getCurrentAnswer(this.props.answers, this.props.fieldName)
        const currentAnswer = parseInt(currentAnswerString, 10)

        this.uiSlider = noUiSlider.create(this.slider, {
            range: range_all_sliders,
            start: currentAnswer,
            tooltips: false,
            pips: {
                mode: 'steps',
                density: 24,
                stepped: true,
                format: {
                    to: () => ''
                }
            },
        })

        this.uiSlider.on('update', (value) => this.updateSlider(value))
    }

    componentWillUnmount() {
        if (this.uiSlider) {
            console.log(this.uiSlider);
            this.uiSlider.off()
        }
    }

    updateSlider(value) {
        const {
            fieldName
        } = this.props
        return this.props.updateAnswer(fieldName, [`${Math.floor(value)}`])
    }

    static calculateLabel(durationInMonths) {
        const duration = Math.floor(durationInMonths);
        if(durationInMonths > 12) {
            return duration/12 + " years"
        } else if(duration === 12) {
            return "1 year"
        }
        else if(duration === 1) {
           return "1 month"
        }
        else {
            return duration + " months"
        }
    }

    render() {

        const {
            answers,
            fieldName
        } = this.props;

        const currentAnswerString = getCurrentAnswer(answers, fieldName)
        const currentAnswer = parseInt(currentAnswerString, 10)
        const durationLabel = DurationQuestion.calculateLabel(currentAnswer)

        return <div>
            <div className={`${style.label}`}>
                <span ref={(label) => this.label = label}>{durationLabel}</span>
            </div>
            <div className={`switch ${style.duration}`}>
                <div ref={(slider) => this.slider = slider}/>
            </div>
        </div>
    }

}

export default DurationQuestion
