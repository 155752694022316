import {connect} from 'react-redux'
import Section from './section'
import {updateAnswerRequest, validateAnswerRequest} from '../../../actions/editor'

const mapStateToProps = (state) => ({
    questions: state.editor.questions,
    answers: state.editor.answers,
    errors: state.editor.errors,
    metaData: state.editor.metaData
})

const mapDispatchToProps = (dispatch) => ({
    validateAnswerRequest: (question, answer) => dispatch(validateAnswerRequest(question, answer)),
    updateAnswer: (question, answer) => dispatch(updateAnswerRequest(question, answer)),
})

const SectionContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Section)

export default SectionContainer
