// import {SET_id_token} from "../actions"


import {CLEAR_FINAL_MODAL, FINAL_DOCUMENT_SUCCESS} from '../actions/final'

const final = (state = { download: {}}, action) => {
    switch (action.type) {
        case CLEAR_FINAL_MODAL:
            return {
                download: {}
            }
        case FINAL_DOCUMENT_SUCCESS:
            return {
                ...state,
                state: action.state,
                id: action.id,
                download: action.download
            }
        default:
            return state
    }
}

export default final
