export const DRAFT_DOCUMENTS_REQUEST = 'DRAFT_DOCUMENTS_REQUEST'
export const NEW_CREDENTIALS = 'NEW_CREDENTIALS'
export const INVALID_CREDENTIALS = 'INVALID_CREDENTIALS'

export const TOKEN_VALIDATE  = 'TOKEN_VALIDATE'
export const TOKEN_REFRESH = 'TOKEN_REFRESH'

export const ADD_CREDENTIALS_TO_STATE = 'ADD_CREDENTIALS_TO_STATE'
export const DRAFT_DOCUMENTS_SUCCESS = 'DRAFT_DOCUMENTS_SUCCESS'
export const DRAFT_DOCUMENTS_FAILURE = 'DRAFT_DOCUMENTS_FAILURE'

export const FINAL_DOCUMENTS_REQUEST = 'FINAL_DOCUMENTS_REQUEST'
export const FINAL_DOCUMENTS_SUCCESS = 'FINAL_DOCUMENTS_SUCCESS'
export const FINAL_DOCUMENTS_FAILURE = 'FINAL_DOCUMENTS_FAILURE'

export const NEW_DOCUMENT_REQUEST = 'NEW_DOCUMENT_REQUEST'
export const NEW_DOCUMENT_SUCCESS = 'NEW_DOCUMENT_SUCCESS'
export const NEW_DOCUMENT_FAILURE = 'NEW_DOCUMENT_FAILURE'

export const GET_DOCUMENT_REQUEST = 'GET_DOCUMENT_REQUEST'
export const GET_DOCUMENT_SUCCESS = 'GET_DOCUMENT_SUCCESS'
export const GET_DOCUMENT_FAILURE = 'GET_DOCUMENT_FAILURE'

export const EDIT_DOCUMENT = 'EDIT_DOCUMENT'

export const DELETE_DOCUMENT_REQUEST = 'DELETE_DOCUMENT_REQUEST'
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS'
export const DELETE_DOCUMENT_FAILURE = 'DELETE_DOCUMENT_FAILURE'

const tokenRefresh = () => ({type: TOKEN_REFRESH})
const tokenValidate = () => ({type: TOKEN_VALIDATE})

const newCredentials = (credentials) => ({type: NEW_CREDENTIALS, credentials: credentials})
const invalidCredentials = () => ({type: INVALID_CREDENTIALS})
const addCredentialsToState = (credentials) => ({type: ADD_CREDENTIALS_TO_STATE, credentials: credentials})

// Get draft documents

const draftDocumentsRequest = () => ({type: DRAFT_DOCUMENTS_REQUEST})
const draftDocumentsSuccess = (draftDocuments) => ({type: DRAFT_DOCUMENTS_SUCCESS, draftDocuments})
const draftDocumentsFailure = () => ({type: DRAFT_DOCUMENTS_FAILURE})

// Get final documents
const finalDocumentsRequest = () => ({type: FINAL_DOCUMENTS_REQUEST})
const finalDocumentsSuccess = (finalDocuments) => ({type: FINAL_DOCUMENTS_SUCCESS, finalDocuments})
const finalDocumentsFailure = () => ({type: FINAL_DOCUMENTS_FAILURE})

//
const editDocumentView = (id) => ({type: EDIT_DOCUMENT, id})

const deleteDocumentRequest = (id) => ({type: DELETE_DOCUMENT_REQUEST, id})
const deleteDocumentSuccess = (id) => ({type: DELETE_DOCUMENT_SUCCESS, id})
const deleteDocumentFailure = () => ({type: DELETE_DOCUMENT_FAILURE})

// New Document
const newDocumentRequest = (templateId, title) => ({type: NEW_DOCUMENT_REQUEST, templateId, title})
const newDocumentSuccess = (id) => ({type: NEW_DOCUMENT_SUCCESS,id})
const newDocumentFailure = () => ({type: NEW_DOCUMENT_FAILURE})

// Get Draft
const getDocumentRequest = (id) => ({type: GET_DOCUMENT_REQUEST, id})
const getDocumentSuccess = (document) => ({type: GET_DOCUMENT_SUCCESS, document})
const getDocumentFailure = () => ({type: GET_DOCUMENT_FAILURE})


export {
    addCredentialsToState,
    invalidCredentials,
    draftDocumentsRequest,
    draftDocumentsFailure,
    draftDocumentsSuccess,
    newDocumentRequest,
    newDocumentSuccess,
    newDocumentFailure,
    newCredentials,
    tokenRefresh,
    tokenValidate,
    getDocumentRequest,
    getDocumentSuccess,
    getDocumentFailure,
    editDocumentView,
    deleteDocumentRequest,
    deleteDocumentSuccess,
    deleteDocumentFailure,
    finalDocumentsRequest,
    finalDocumentsSuccess,
    finalDocumentsFailure
}
