import React, {Component} from 'react';
import * as _ from "lodash";
import M from 'materialize-css';


import style from './select.module.scss'

class Select extends Component {

    constructor(props){
        super(props);
        this._onChange = this._onChange.bind(this);
    }

    componentDidMount() {
        M.FormSelect.init(this.select, {
            change: this._onChange
        });
    }

    componentWillUnmount () {
        const select = M.FormSelect.getInstance(this.select);
        select.destroy()
    }

    //Based on this: https://github.com/react-materialize/react-materialize/blob/d1c82a1eabec92563dcb642afe8bf837c788664a/src/Input.js
    _getSelectedOptions({options}) {
        return Array.from(options).filter(opt => opt.selected).map(opt => opt.value);
    }

    _onChange(event) {
        const {
            fieldName,
        } = this.props;

        const targetAnswer = this._getSelectedOptions(event.target);
        return this.props.updateAnswer(fieldName,targetAnswer);
    }

    render() {
        const {
            answers,
            fieldName,
            options
        } = this.props;

        const currentAnswer = _.get(answers, fieldName);
        return <div className={`input-field ${style.select}`}>
            <select value={currentAnswer} ref={div => {this.select = div;}} onChange={this._onChange}>
                <option value="" disabled>Choose your option</option>
                {options.map((option, idx) => {
                    const isSelected = option.value === currentAnswer;
                    return <option key={option.value} value={option.value} isselected={isSelected.toString()}>{option.label}</option>
                })}
                </select>
        </div>
    }
}

export default Select;
