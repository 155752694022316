// import {SET_id_token} from "../actions"

import {DOCUMENT_REVIEW_SUCCESS} from "../actions/review";

const review = (state = {}, action) => {
    switch (action.type) {
        case DOCUMENT_REVIEW_SUCCESS:
            return {
                ...state,
                title: action.title,
                markdown: action.markdown,
                version: action.version
            }
        default:
            return state
    }
}

export default review
