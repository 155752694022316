import config from '../config'

const LOCAL_STORAGE_CREDENTIALS = 'credentials';

const localStorage = window.localStorage;

/**
 * Forces the window to re-direct to the sign in window in Cognito
 * @return {Promise<string>}
 */
const signIn = async () => {
    let redirectPath = window.location.host;
    if (redirectPath === 'localhost:3000') {
        redirectPath = 'lvh.me:3000'
    }
    return window.location.href = `${config.congitoURL}/login?response_type=token&client_id=${config.userPoolWebClientId}&redirect_uri=https://${redirectPath}/oauth2/token`
};

const persistCredentials = (credentials) => localStorage.setItem(LOCAL_STORAGE_CREDENTIALS, JSON.stringify(credentials))
const retrieveCredentials = () => JSON.parse(localStorage.getItem(LOCAL_STORAGE_CREDENTIALS))
const removeCredentials = () => localStorage.removeItem(LOCAL_STORAGE_CREDENTIALS)

export default {
    persistCredentials,
    retrieveCredentials,
    removeCredentials,
    signIn
}
