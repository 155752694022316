import React, {Component} from 'react'

import 'materialize-stepper/materialize-stepper.css'
import styles from './editor.module.scss'
import SectionStep from './sectionStep'
import PropTypes from 'prop-types'
import Loader from '../common/loader/loader'

class Editor extends Component {

    componentDidMount() {

    }

    render() {
        const {
            activeSection,
            documentId,
            sections,
            sectionStatus,
            goToSection
        } = this.props
        return (
            <div className={`${styles.editor}`}>
                <ul className={`stepper linear`}>
                    {this.props.sections && sections.map((section, idx) => {
                        return <SectionStep key={idx} idx={idx}
                                            documentId={documentId}
                                            {...section}
                                            sectionStatus={sectionStatus[idx]}
                                            isActive={activeSection === idx}
                                            goToSection={goToSection}
                                            totalSections={sections.length}
                        />
                    })}
                    {!this.props.sections &&
                    <Loader/>
                    }
                </ul>
            </div>
        )
    }
}

Editor.propTypes = {
    sections: PropTypes.arrayOf(PropTypes.object),
    activeSection: PropTypes.number
}

export default Editor
