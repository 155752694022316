import showdown from 'showdown'

/*
BASED ON THIS: https://github.com/showdownjs/table-extension/blob/master/src/showdown-table.js
 */

const tableHandler = (converter) => {
    return {
        td: function (cell) {
            // return '<td style="' + style + '">' + text.replace + '</td>'
            let cellContent = converter.makeHtml(cell)
            cellContent = cellContent.replace(/<\/?p[^>]*>/g, '')
            return '<td>' + cellContent + '</td>'
        },
        tds: function () {
            var out = '', i = 0, ds = [].slice.apply(arguments[0])
            for (i; i < ds.length; i += 1) {
                out += this.td(ds[i]) + '\n'
            }
            return out
        },
        tr: function () {
            var out,
                cs = [].slice.apply(arguments[0])
            out = '<tr>\n'
            out += this.tds(cs)
            out += '</tr>\n'
            return out
        },
        th: function (header) {
            if (header.trim() === '') {
                return '';
            }
            var id = header.trim().replace(/ /g, '_').toLowerCase();
            return '<th id="' + id + '">' + header + '</th>';
        },
        ths: function () {
            var out = '',
                i = 0,
                hs = [].slice.apply(arguments[0])
            for (i; i < hs.length; i += 1) {
                out += this.th(hs[i]) + '\n'
            }
            return out
        },
        thead: function () {
            var out,
                hs = [].slice.apply(arguments[0])
            out = '<thead>\n'
            out += '<tr>\n'
            out += this.ths(hs)
            out += '</tr>\n'
            out += '</thead>\n'
            return out
        }
}
}


const pandocTables = {
    type: 'lang',
    filter: function (text, converter, options) {
        // ... do stuff to text ...
        var i = 0, lines = text.split('\n'), line, out = []

        for (i; i < lines.length; i += 1) {
            line = lines[i]
            // looks like a table heading
            if (line.trim().match(/^[|].*[|]$/)) {
                line = line.trim()

                const table = tableHandler(converter)

                const tbl = []
                tbl.push('<table>')

                //Check if it has a header
                const nextLine = lines[i + 1].trim()
                if (nextLine.match(/^[|] [- |]*[|]$/)) {
                    const hs = line.substring(1, line.length - 1).split('|')
                    tbl.push(table.thead(hs))
                    ++i;
                }

                line = lines[++i]
                tbl.push('<tbody>')
                while (line.trim().match(/^[|].*[|]$/)) {
                    line = line.trim()
                    tbl.push(table.tr(line.substring(1, line.length - 1).split('|')))
                    line = lines[++i]
                }
                tbl.push('</tbody>')
                tbl.push('</table>')
                // we are done with this table and we move along
                out.push(tbl.join('\n'))

                continue

            }
            out.push(line)
        }
        return out.join('\n')
    }
}

showdown.extension('pandocTable', () => {
    return [pandocTables]
})
