import {select, takeLatest, put} from 'redux-saga/effects'
import _ from 'lodash'

import {
    NEW_DOCUMENT_REQUEST,
    DELETE_DOCUMENT_REQUEST,
    EDIT_DOCUMENT,
    GET_DOCUMENT_REQUEST,
    DRAFT_DOCUMENTS_REQUEST,
    draftDocumentsFailure,
    draftDocumentsSuccess,
    getDocumentFailure,
    getDocumentSuccess,
    deleteDocumentSuccess,
    newDocumentSuccess,
    newDocumentFailure, FINAL_DOCUMENTS_REQUEST, finalDocumentsSuccess, finalDocumentsFailure,
} from '../actions'

import apiGraphql from '../api/graphql'
import history from '../services/history'
import createDocument from '../api/graphql/mutations/createDocument'
import gqlHandler from './gqlHandler'
import {all} from '../../node_modules/redux-saga/effects'
import {UPDATE_TITLE_REQUEST, updateTitleSuccess} from '../actions/editor'

function* getDocuments() {
    console.log('documentSaga/getDocuments')

    const queryData = yield gqlHandler.query(apiGraphql.queries.getDocuments)
    const docList = _.get(queryData, 'data.listDocuments.items')

    if (docList) {
        yield (put(draftDocumentsSuccess(docList)))
    } else {
        yield (put(draftDocumentsFailure()))
    }
}

function* getFinalisedDocuments() {
    console.log('documentSaga/getFinalisedDocuments')
    const queryData = yield gqlHandler.query(apiGraphql.queries.getFinalisedDocuments)
    const finalList = _.get(queryData, 'data.listFinalisedDocuments.items')

    if (finalList) {
        yield (put(finalDocumentsSuccess(finalList)))
    } else {
        yield (put(finalDocumentsFailure()))
    }
}

function* newDocument(event) {
    console.log('documentSaga/newDocument')

    const newDocument = yield gqlHandler.mutate(createDocument, {
        input: {
            title: event.title,
            templateId: event.templateId
        }
    })

    const id = newDocument.data.createDocument.id
    if (id) {
        yield all([put(newDocumentSuccess(id)), history.push(`/editor/${id}/draft`)])
    } else {
        yield (put(newDocumentFailure()))
    }
}

function* getDocument(event) {
    const id = event.id

    const queryData = yield gqlHandler.query(
        apiGraphql.queries.getDocument,
        {
            id
        }
    )

    const doc = _.get(queryData, 'data.getDocument')
    if (doc) {
        let template
        try {
            template = JSON.parse(atob(doc.template.content))
        } catch (e) {
            yield (put(getDocumentFailure(e.message)))
            return
        }

        yield (put(getDocumentSuccess({
            ...doc,
            template: template
        })))

    } else {
        yield (put(getDocumentFailure()))
    }
}

function* editDocument(event) {
    yield history.push(`/editor/${event.id}/draft`)
}

function* deleteDocument(event) {
    console.log('documentSaga/deleteDocument')
    yield all([
        yield put(deleteDocumentSuccess(event.id)),
        yield gqlHandler.mutate(apiGraphql.mutations.deleteDocument, {
            input: {
                id: event.id
            },
        })
    ])
}

function* updateTitle(event) {
    const documentId = yield select(state => state.editor.documentId)

    console.log(documentId, ' ', event.title)

    yield gqlHandler.mutate(apiGraphql.mutations.updateDocument, {
        input: {
            id: documentId,
            title: event.title
        }
    })
    yield (put(updateTitleSuccess(event.title)))
}

function* documentsSaga() {
    yield takeLatest(DRAFT_DOCUMENTS_REQUEST, getDocuments)
    yield takeLatest(FINAL_DOCUMENTS_REQUEST, getFinalisedDocuments)
    yield takeLatest(NEW_DOCUMENT_REQUEST, newDocument)
    yield takeLatest(DELETE_DOCUMENT_REQUEST, deleteDocument)
    yield takeLatest(GET_DOCUMENT_REQUEST, getDocument)
    yield takeLatest(EDIT_DOCUMENT, editDocument)
    yield takeLatest(UPDATE_TITLE_REQUEST, updateTitle)

}

export default documentsSaga
