import React, {Component} from 'react'

import styles from './reviewDocument.module.scss'
import PropTypes from 'prop-types';
import Markdown from '../../markdown/markdown'
import Button from '../../common/button/button'
import BackButton from '../../common/backButton/backButton'
import FinalModelContainer from '../../finalModel/finalModel.container'

class ReviewDocument extends Component {

    componentDidMount() {
        // this.props.getDraftDocuments()
        this.props.reviewDocument(this.props.match.params.documentId)
    }

    render() {
        const documentId=this.props.match.params.documentId;

        const {
            title,
            version,
            documentFinaliseRequest,
            markdown
        } = this.props;

        return (
            <div className={`${styles.documentList}`}>
                <div className={`${styles.bar}`}>
                    <div className={`${styles.left}`}>
                        <h1>Review - {title}</h1>
                    </div>
                    <div className={`${styles.right}`}>
                        <BackButton documentId={documentId} />
                        <Button name={'Finalise'} inverted={true}
                                dataTarget={'finalContainer'}
                                className={'modal-trigger'}
                                onClick={()=>documentFinaliseRequest(documentId, version)}/>
                        <FinalModelContainer />
                    </div>
                </div>
                <Markdown markdown={markdown} className={`${styles.markdown}`} />
            </div>
        )
    }
}

ReviewDocument.propTypes = {
    getDraftDocuments: PropTypes.func
};


export default ReviewDocument
