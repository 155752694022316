
import gql from 'graphql-tag'

export default gql`
mutation($input: FinaliseDraftInput!) {
finaliseDraftDocument(input:$input) {
    documentId
    finaliseId
  }
}`
