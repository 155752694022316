import React, {Component} from 'react'

import styles from './tabMenu.module.scss'
import PropTypes from "prop-types";
import {Link} from 'react-router-dom'

class TabItem extends Component {
    render() {
        const {
            name,
            active,
            path
        } = this.props;

        return (
            <li className={`${styles.tab}`}>
                <Link to={`/documents/${path}`}
                   className={`${active ? styles.active : ''}`}>{name}</Link>
            </li>
        )
    }
}

TabItem.propTypes = {
    name: PropTypes.string.isRequired,
    active: PropTypes.bool,
    path: PropTypes.string.isRequired
};

export default TabItem
