
export const FINAL_DOCUMENT_SUCCESS = 'FINAL_DOCUMENT_SUCCESS'
export const FINAL_DOCUMENT_FAILURE = 'FINAL_DOCUMENT_FAILURE'
export const CLEAR_FINAL_MODAL = 'CLEAR_FINAL_MODAL'

const finalDocumentRequestSuccess = (id, state, download ) => ({type: FINAL_DOCUMENT_SUCCESS, id, state, download})
const finalDocumentRequestFailure = (id) => ({type: FINAL_DOCUMENT_FAILURE, id})

const clearFinalModel = () => ({type: CLEAR_FINAL_MODAL})

export {
    clearFinalModel,
    finalDocumentRequestSuccess,
    finalDocumentRequestFailure
}
