import {takeEvery} from 'redux-saga'
import {put} from 'redux-saga/effects'
import apiGraphql from '../api/graphql'
import gqlHandler from './gqlHandler'

import {
    DOCUMENT_FINALISE_REQUEST,
    DOCUMENT_REVIEW_REQUEST, documentFinaliseRequestSuccess,
    reviewDocumentRequestFailure,
    reviewDocumentRequestSuccess
} from '../actions/review'
import _ from 'lodash'

function* documentReview(action) {
    console.log('reviewSaga/documentReview')
    const id = action.id

    const updateReview = yield gqlHandler.query(apiGraphql.queries.generateDocument,
        {
            input: {
                id
            }
        })

    const markdown = _.get(updateReview, 'data.generateDocument.markdown')
    const version = _.get(updateReview, 'data.generateDocument.version')
    const title = _.get(updateReview, 'data.generateDocument.title')

    if (markdown) {
        yield (put(reviewDocumentRequestSuccess(
            id, markdown, version, title)))
    } else {
        yield (put(reviewDocumentRequestFailure(id)))
    }
}

function* finaliseDocument(action) {
    console.log('reviewSaga/finaliseDocument')

    const id = action.id
    const version = action.version

    const finaliseDocument = yield gqlHandler.mutate(apiGraphql.mutations.finaliseDocument,
        {
            input: {
                id,
                version
            },
        })
    
    const finaliseId = _.get(finaliseDocument, 'data.finaliseDraftDocument.finaliseId')

    yield (put(documentFinaliseRequestSuccess(
        finaliseId, version)))
}


function* reviewSaga() {
    yield takeEvery(DOCUMENT_REVIEW_REQUEST, documentReview)
    yield takeEvery(DOCUMENT_FINALISE_REQUEST, finaliseDocument)

}

export default reviewSaga
