import React, {Component} from 'react'

import styles from './draftDocument.module.scss'
import PropTypes from 'prop-types'
import EditorContainer from '../../editor/editor.container'
import DocumentTitleEdit from './title/documentTitleEdit'
import BackButton from '../../common/backButton/backButton'

class DraftDocument extends Component {

    componentDidMount() {
        this.props.getDocument(this.props.match.params.documentId)
    }

    render() {
        return (
            <div className={`${styles.documentList}`}>
                <div className={`${styles.bar}`}>
                    <div className={`${styles.left}`}>
                        <div className={`${styles.docTitle}`}>
                            <DocumentTitleEdit {...this.props} />
                        </div>
                    </div>
                    <div className={`${styles.right}`}>
                        <BackButton />
                    </div>
                </div>
                <EditorContainer documentId={this.props.match.params.documentId}/>
            </div>
        )
    }
}

DraftDocument.propTypes = {
    getDraftDocuments: PropTypes.func,
    updateTitleRequest: PropTypes.func,
}


export default DraftDocument
