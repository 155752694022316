import {delay, takeEvery} from 'redux-saga'
import _ from 'lodash'

import {DOCUMENT_FINALISE_SUCCESS} from '../actions/review'
import {put} from '../../node_modules/redux-saga/effects'
import apiGraphql from '../api/graphql'
import finalisedState from '../consts/finalisedState'
import {finalDocumentRequestSuccess} from '../actions/final'
import gqlHandler from './gqlHandler'

function* finaliseDocument(action) {
    console.log('@@@@@@@@ PONG', action)
    const finalisedId = action.id

    let i = 0
    while (i < 20) {
        //10 is terrible
        const getFinalisedDocument = yield gqlHandler.query(apiGraphql.queries.getFinalisedDocument,
            {
                id: finalisedId
            }
        )

        /*
        Document:
{data: {…}, loading: false, networkStatus: 7, stale: false}
data:
finalisedDocument:
state: "completed"
         */
        const state = _.get(getFinalisedDocument, 'data.finalisedDocument.state')
        const download = _.get(getFinalisedDocument, 'data.finalisedDocument.download')
        if (state === finalisedState.completed) {
            yield put(finalDocumentRequestSuccess(finalisedId, state, download))
            break
        }
        // TODO: HANDLE ERROR

        i++
        yield delay(2000)
    }


}

function* finaliseSaga() {
    yield takeEvery(DOCUMENT_FINALISE_SUCCESS, finaliseDocument)

}

export default finaliseSaga
