import {connect} from 'react-redux'
import _ from 'lodash'

import ReviewSection from './reviewSection'
import {goToSection, validateAndReviewRequest} from '../../../actions/editor'
import sectionStatus from '../../../consts/sectionStatus'

const sectionsInErrorTitle = (state, ownProps) => {
    const sectionTitle = []
    _.forEach(state.editor.sectionStatus, (status, sectionIndx) => {
        if (status === sectionStatus.IN_ERROR) {
            sectionTitle.push({
                sectionIndx: sectionIndx,
                title: state.editor.sections[sectionIndx].title
            })
        }
    })

    return sectionTitle
}

const mapStateToProps = (state, ownProps) => ({
        ...ownProps,
    documentId: ownProps.documentId,
    sectionsInErrorTitle: sectionsInErrorTitle(state, ownProps)
    // questions: state.editor.questions,
    // answers: state.editor.answers,
    // errors: state.editor.errors,
    // metaData: state.editor.metaData
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    reviewDocument: () => dispatch(validateAndReviewRequest(ownProps.documentId)),
    goToSection: (sectionIdx) => dispatch(goToSection(sectionIdx))
})

const ReviewSectionContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ReviewSection)

export default ReviewSectionContainer
