import {put, take, fork, select, takeEvery, call, cancel} from 'redux-saga/effects'
import _ from 'lodash'
import jsonLogic from 'json-logic-js'
import {validateField} from 'machine-legal_common/services/fieldValidator'
import {validateDocumentBySection} from 'machine-legal_common/services/documentValidator'

import {
    goToSection,
    INVALID_ANSWER,
    invalidAnswer,
    invalidSection,
    VALID_ANSWER,
    validAnswer,
    VALIDATE_ANSWER_REQUEST,
    validSection,
    validateAnswerRequest,
    completeSection,
    UPDATE_ANSWER_REQUEST,
    questionVisibility,
    VALIDATE_AND_REVIEW_REQUEST, invalidAnswerBackground
} from '../actions/editor'
import {GET_DOCUMENT_SUCCESS} from '../actions'
import history from '../services/history'

export const getErrors = (state) => state.editor.errors
export const getSections = (state) => state.editor.sections
export const getAnswers = (state) => state.editor.answers


const getQuestionsInSectionFromIdx = (sections, sectionIdx) => _.get(sections, `[${sectionIdx}].questions`, [])
const getFieldNamesInSection = (sections, sectionIdx) => _.map(getQuestionsInSectionFromIdx(sections, sectionIdx), question => question.fieldName)

const getQuestionFromSection =
    (section, fieldName) => section.questions
        .filter(question => question.fieldName === fieldName)
        .shift()

const getQuestionFromMetaData = (metaData, fieldName) => metaData[fieldName]

function* getDocumentSuccess(action) {
    console.log('editorSaga/getDocumentSuccess')
    yield put(goToSection(0))
}


function* validateAnswer(sectionIdx, action) {
    console.log('editorSaga/validateAnswer')
    const sections = yield select(state => state.editor.sections)
    const metaData = yield select(state => state.editor.metaData)

    const questionDefinition = getQuestionFromSection(sections[sectionIdx], action.fieldName)
    const metaDataDefintion = getQuestionFromMetaData(metaData, action.fieldName)

    const validation = validateField(questionDefinition, action.value, metaDataDefintion)

    if (!validation.isValid) {
        yield put(invalidAnswer(action.fieldName, validation.validationMsg))

    } else {
        yield put(validAnswer(action.fieldName))
    }
}


function* getInvalidQuestions(sectionIdx) {
    const errors = yield select(getErrors)
    const sections = yield select(getSections)
    return getFieldNamesInSection(sections, sectionIdx).filter(q => _.has(errors, `${q}`))
}


function* validateSectionBasedOnAction(sectionIdx, action) {
    console.log('editorSaga/validateSectionBasedOnAction')

    if (action.type === INVALID_ANSWER) {
        console.log("WHADSDSDSD :" , action, " sectionIdx: ", sectionIdx)
        yield put(invalidSection(sectionIdx))
    } else if (action.type === VALID_ANSWER) {
        const questionsInError = yield call(getInvalidQuestions, sectionIdx)
        if (questionsInError.length === 0) {
            yield put(validSection(sectionIdx))
        }
    } else {
        throw new Error('No action specified')
    }
}

function* validatePreviousSection(sectionIdx) {
    console.log('editorSaga/validatePreviousSection')
    const sections = yield select(getSections)
    const answers = yield select(getAnswers)

    const questionsInSection = getQuestionsInSectionFromIdx(sections, sectionIdx)

    for (const questionIndx in questionsInSection) {
        const questionDef = questionsInSection[questionIndx]
        yield call(validateAnswer, sectionIdx, validateAnswerRequest(questionDef.fieldName, answers[questionDef.fieldName]))
    }

    const questionsInError = yield call(getInvalidQuestions, sectionIdx)
    if (questionsInError.length === 0) {
        yield put(completeSection(sectionIdx))
    } else {
        yield put(invalidSection(sectionIdx))
    }
}

function* showIfInSection(sectionIdx) {
    const sections = yield select(getSections)
    const answers = yield select(getAnswers)

    const questionsInSection = getQuestionsInSectionFromIdx(sections, sectionIdx)
    let questionsWithShow = _.chain(questionsInSection)
        .flatMap()
        .filter(question => _.has(question, 'showIf'))
        .map(question => {
            const showIf = jsonLogic.apply(question.showIf, answers)
            return {
                fieldName: question.fieldName,
                show: showIf
            }
        })
        .value()


    for (const questionIndx in questionsWithShow) {
        const questionWithShow = questionsWithShow[questionIndx]
        yield put(questionVisibility(sectionIdx, questionWithShow.fieldName, questionWithShow.show))
    }
}

function* sectionMonitor() {
    let previousSectionIdx = null
    let valAnswer
    let valSection
    let showIfSection
    while (true) {
        const action = yield take('GO_TO_SECTION')
        const sectionIdx = action.sectionIdx

        if (valAnswer) {
            yield cancel(valAnswer)
        }
        if (valSection) {
            yield cancel(valSection)
        }
        if (showIfSection) {
            yield cancel(showIfSection)
        }

        if (previousSectionIdx !== null) {
            yield call(validatePreviousSection, previousSectionIdx)
        }

        // Fire calculate visibiliy
        yield call(showIfInSection, sectionIdx)

        valAnswer = yield takeEvery(VALIDATE_ANSWER_REQUEST, validateAnswer, sectionIdx)
        valSection = yield takeEvery([INVALID_ANSWER, VALID_ANSWER], validateSectionBasedOnAction, sectionIdx)
        showIfSection = yield takeEvery([UPDATE_ANSWER_REQUEST], showIfInSection, sectionIdx)


        previousSectionIdx = sectionIdx
    }
}

function* validateAndReview(action) {
    console.log('editorSaga/validateAndReview')

    //Check that document is valid
    const sections = yield select(getSections)
    const answers = yield select(getAnswers)

    const validations = yield call(validateDocumentBySection, {sections: sections}, answers)

    const invalidSections = _(validations).filter(validation => !validation.isValid).value()
    const anySectionInvalid = invalidSections.length > 0
    if(anySectionInvalid) {
        //Invalid
        console.log("Invalid DOcument")
        for(const invalidSec of invalidSections) {
            for(const question of invalidSec.validations) {
                if(!question.isValid){
                    console.log("OHH NO: ",  question.validationMsg);
                    yield put(invalidAnswerBackground(question.fieldName, question.validationMsg))
                }
            }

            yield put(invalidSection(invalidSec.sectionIdx))
        }
    } else {
        console.log("Valid Document")
        yield history.push(`/editor/${action.id}/review`)
    }
}


function* editorSaga() {
    yield fork(sectionMonitor)
    yield takeEvery(GET_DOCUMENT_SUCCESS, getDocumentSuccess)
    yield takeEvery(VALIDATE_AND_REVIEW_REQUEST, validateAndReview)

}

export default editorSaga
