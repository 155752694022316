
import gql from 'graphql-tag'

export default gql`
query finalisedDocument($id: String!) {
  finalisedDocument(id:$id) {
    title
    state
    download {
    	wordUrl
	    pdfUrl
	    markdownUrl
	    thumbNailUrl
    }
  }
}
`
