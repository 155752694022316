import {Component, Fragment} from 'react'
import styles from './documentTitle.module.scss'
import PropTypes from 'prop-types'
import React from 'react'

class DocumentTitleEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {title: props.title, editingTitle: false};
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.title !== this.props.title){
            this.setState({title:nextProps.title});
        }
    }

    updateTitleText(event) {
        this.setState({
            title: event.target.value,
        })
    }

    updateTitleRequest() {
        this.props.updateTitleRequest(this.state.title)

        this.setState({
            editingTitle: false
        })
    }

    editTitle() {
        this.setState({
            editingTitle: true
        })
    }

    keyDown(event) {
        console.log(event.keyCode)
        if (event.keyCode === 13) {
            this.updateTitleRequest()
        }
    }


    render() {
        const {
            editingTitle,
            title
        } = this.state;

        return <div className={`${styles.editTitle}`}>
            {!editingTitle && <Fragment>
                <h1 onClick={() => this.editTitle()}>{title}</h1>
            </Fragment>}
            {editingTitle && <Fragment>
                <input type="text"
                       ref={editInput => editInput && editInput.focus()}
                       value={title}
                       onKeyDown={(event) => this.keyDown(event)}
                       onMouseLeave={() => this.updateTitleRequest()}
                       onBlur={() => this.updateTitleRequest()}
                       onChange={(e)=> this.updateTitleText(e)} />
            </Fragment>}
        </div>
    }
}

DocumentTitleEdit.propTypes = {
    title: PropTypes.string
}

export default DocumentTitleEdit
