import _ from 'lodash'

import {DELETE_DOCUMENT_SUCCESS, DRAFT_DOCUMENTS_SUCCESS, FINAL_DOCUMENTS_SUCCESS} from '../actions'
import {GET_TEMPLATES_SUCCESS} from '../actions/documents'
import * as dotProp from 'dot-prop-immutable'

const deleteDocument = (state = {}, action) => {
    const documentDeletedId = action.id
    const index = _.findIndex(state.drafts, draft => draft.id === documentDeletedId)
    try {
        return dotProp.delete(state, `drafts.${index}`)
    } catch (e) {
        console.log('Cannot delete from array: ', e.message)
        return state
    }
}

const documents = (state = {}, action) => {
    switch (action.type) {
        case GET_TEMPLATES_SUCCESS:
            return {
                ...state,
                templates: action.templates
            }
        case DRAFT_DOCUMENTS_SUCCESS:
            return {
                ...state,
                drafts: action.draftDocuments
            }
        case FINAL_DOCUMENTS_SUCCESS:
            return {
                ...state,
                final: action.finalDocuments
            }
        case DELETE_DOCUMENT_SUCCESS:
            return deleteDocument(state, action)
        default:
            return state
    }
}

export default documents
