// import {SET_id_token} from "../actions"
import _ from 'lodash'
import * as dotProp from 'dot-prop-immutable'

import sectionStatus from '../consts/sectionStatus'

import {GET_DOCUMENT_SUCCESS} from '../actions'
import {
    ANSWER_UPDATE,
    GO_TO_SECTION,
    UPDATE_ANSWER_REQUEST,
    INVALID_ANSWER,
    VALID_ANSWER,
    INVALID_SECTION,
    VALID_SECTION,
    COMPLETE_SECTION,
    QUESTION_VISIBLITY,
    INVALID_ANSWER_BACKGROUND
} from '../actions/editor'

const editor = (state = {}, action) => {
    switch (action.type) {
        case GET_DOCUMENT_SUCCESS:
            return {
                ...state,
                errors: {},
                sectionStatus: _.fill(Array(action.document.template.sections.length), sectionStatus.INCOMPLETE),
                documentId: action.document.id,
                title: action.document.title,
                sections: action.document.template.sections,
                metaData: {}
                // activeSection: 0,
                // questions: action.document.template.sections[0].questions
            }
        case GO_TO_SECTION:
            return {
                ...state,
                activeSection: action.sectionIdx,
                questions: state.sections[action.sectionIdx].questions,
            }
        case UPDATE_ANSWER_REQUEST:
            return dotProp.set(state, `answers.${action.fieldName}`, action.value)
        case ANSWER_UPDATE:
            return {
                ...state,
                answers: action.answers
            }
        case QUESTION_VISIBLITY:
            return dotProp.set(state,`metaData.${action.fieldName}.show`, action.show)
        case COMPLETE_SECTION:
            return dotProp.set(state, `sectionStatus.${action.sectionIdx}`, sectionStatus.COMPLETE)
        case INVALID_ANSWER || INVALID_ANSWER_BACKGROUND:
            return dotProp.set(state, `errors.${action.fieldName}`, action.reason)
        case INVALID_SECTION:
            return dotProp.set(state, `sectionStatus.${action.sectionIdx}`, sectionStatus.IN_ERROR)
        case VALID_ANSWER:
            return dotProp.delete(state, `errors.${action.fieldName}`)
        case VALID_SECTION:
            return dotProp.set(state, `sectionStatus.${action.sectionIdx}`, sectionStatus.INCOMPLETE)
        default:
            return state
    }
}

export default editor
