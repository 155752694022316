import React, {Component} from 'react'

import styles from './documentsList.module.scss'
import Button from "../../common/button/button"
import TabMenu from "../../common/tabMenu/tabMenu"
import TabItem from "../../common/tabMenu/tabItem";
import PropTypes from "prop-types";
import Modal from '../../common/modal/modal'
import NewDocumentContainer from './newDocument/newDocument.container'
import DraftList from './draftList'
import FinalList from './finalList'

class DocumentsList extends Component {

    render() {
        const viewType = this.props.match.params.viewType

        let {draftActive,finalActive} = false
        let docRender;
        if (!viewType || viewType === 'drafts') {
            draftActive = true
            docRender = <DraftList {...this.props} />
        } else if (viewType === 'final') {
            finalActive = true
            docRender = <FinalList {...this.props} />
        } else {
                //TODO: REplace
            throw new Error('WOW')
        }

        return (
            <div className={`${styles.documentList}`}>
                <div className={`${styles.bar}`}>
                    <div className={`${styles.left}`}>
                        <h1>documents</h1>
                    </div>
                    <div className={`${styles.right}`}>
                        <Button name={'New Document'}
                                inverted={true}
                                className={`modal-trigger`}
                                dataTarget={'newDocument'}
                                onClick={()=>console.log('hello')}/>
                    </div>
                    <Modal id={`newDocument`} title={`New Document`} type={`Wide`} >
                        <NewDocumentContainer />
                    </Modal>
                </div>
                <TabMenu>
                    <TabItem name={'Drafts'} active={draftActive} path={'drafts'} />
                    <TabItem name={'Final'} active={finalActive} path={'final'} />
                    {/*<TabItem name={'Shared'}/>*/}
                    {/*<TabItem name={'Deleted'} active={deletedActive} path={'deleted'}/>*/}
                </TabMenu>
                {docRender}
            </div>
        )
    }
}

DocumentsList.propTypes = {
    getFinalDocuments: PropTypes.func,
    getDraftDocuments: PropTypes.func
};


export default DocumentsList
