import {connect} from 'react-redux'
import DraftDocument from './draftDocument'
import {getDocumentRequest} from '../../../actions'
import {updateTitleRequest} from '../../../actions/editor'
// import Questions from './Questions'
// import {listAnswers, saveAnswer, updateAnswer} from '../actions'

const mapStateToProps = (state) => ({
    title : state.editor.title
    // draftDocuments: state.documents.drafts,
    // questions: state.questions.answers
})

const mapDispatchToProps = dispatch => ({
    getDocument: (id) => dispatch(getDocumentRequest(id)),
    updateTitleRequest: (title) => dispatch(updateTitleRequest(title)),
    // newDocumentRequest: () => dispatch(newDocumentRequest())
    // listAnswers: () => dispatch(listAnswers()),
    // saveAnswer: (question, answer) => dispatch(saveAnswer(question, answer)),
    // updateAnswerRequest: (question, answer) => dispatch(updateAnswer(question, answer))
})

const DraftDocumentContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(DraftDocument)

export default DraftDocumentContainer
