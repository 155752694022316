import {Component} from 'react'
import styles from './documentsList.module.scss'
import Loader from '../../common/loader/loader'
import React from 'react'
import FinalItem from './finalItem/finalItem'
import Button from '../reviewDocument/reviewDocument'
import FinalModelContainer from '../../finalModel/finalModel.container'


class FinalList extends Component {

    componentDidMount() {
        console.log("DocumentsList/FinalList/componentDidMount")
        this.props.getFinalDocuments()
    }

    render() {
        const {
            documentFinaliseRequest,
            finalDocuments
        } = this.props

        console.log("props: ", this.props)

        if (finalDocuments) {
            const finalItems = finalDocuments.map((final =>
                <FinalItem
                    key={final.id}
                    docId={final.id}
                    documentFinaliseRequest={documentFinaliseRequest}
                    {...final}
                />))
            return <div className={`${styles.items}`}>
                {finalItems}
                <FinalModelContainer />
            </div>
        } else {
            return <div className={`${styles.documentLoaderWrapper}`}>
                <Loader/>
            </div>
        }

         // return <div className={`${styles.documentLoaderWrapper}`}>
         //        <Loader/>
         //    </div>
        }

}

export default FinalList
