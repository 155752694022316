import React, {Component} from 'react'
import showdown from 'showdown'
import './customTableExtension'

import styles from './markdown.module.scss'
import PropTypes from 'prop-types'
import Loader from '../common/loader/loader'

class Markdown extends Component {

    constructor(props) {
        super(props)
        this.converter = new showdown.Converter({
            tables: true,
            metadata: true,
            extensions: ['pandocTable']
        })

    }

    componentDidMount() {

    }

    render() {
        const {
            markdown,
            className
        } = this.props

        const html = this.converter.makeHtml(markdown)

        return (
            <div className={`${styles.markdown}`}>
                {!markdown && <Loader/>}
                {markdown && <div dangerouslySetInnerHTML={{__html: html}} className={`${className}`}/>}
            </div>
        )
    }
}

Markdown.propTypes = {
    markdown: PropTypes.string,
}

export default Markdown
