import gql from 'graphql-tag'

export default gql`
mutation updateDocument($input: UpdateDocumentInput!) {
    updateDocument(input: $input) {
        id
        title
    }
}
`


