import React, {Component} from 'react'
import _ from 'lodash'
import M from 'materialize-css'

import styles from './tagQuestion.module.scss'

class TagQuestion extends Component {

    componentDidMount() {
        M.Chips.init(this.tag,
            {
                data: this.getChipValues(),
                onChipAdd: (event, chip) => this.updateQuestion('add', event, chip),
                onChipDelete: (event, chip) => this.updateQuestion('delete', event, chip)
            })
    }

    componentWillUnmount() {
        const chips = M.Chips.getInstance(this.tag)
        chips.destroy()
    }

    getChipValues() {
        const currentAnswer = _.get(this.props.answers, this.props.fieldName)
        return _.map(currentAnswer, (tag, index) => ({tag: tag, index: index}))
    }


    updateQuestion(action, event, chip) {
        const fieldName = this.props.fieldName
        const inst = M.Chips.getInstance(this.tag)
        const chipsDataObjs = inst.chipsData

        const chipLabels = _(chipsDataObjs).map(chipObj => chipObj.tag).value()
        // this.props.onUpdateQuestion(fieldName, chipLabels);
        this.props.updateAnswer(fieldName, chipLabels)
        this.props.validateAnswerRequest(fieldName, chipLabels)

        /*
                return this.props.updateAnswer(fieldName, [event.target.value])
    }

    handleBlur(event, fieldName) {
        console.log("Handle Blur", event, fieldName)
        // //Stops the materialize JQuery stuff getting in the way of the magical validation
        // event.stopPropagation();
        // event.nativeEvent.stopImmediatePropagation();
        return this.props.validateAnswerRequest(fieldName, [event.target.value]);
         */

    }

    render() {

        const {
            error
        } = this.props

        let errorClass="valid"
        if(error) {
            errorClass="invalid"
        }

        /*
            The select-wrapper is a hack to allow the field validation message to show
        */
        return <div className={`select-wrapper ${errorClass}`}>
            <div className={`purpose-chips ${styles['purpose-chips']}`} ref={div => {
                this.tag = div
            }}/>
            {/*<input type="text" id={`${fieldName}`} name={`${fieldName}`} className={errorClass} />*/}

        </div>
    }
}

export default TagQuestion
