import {Component} from 'react'
import DocumentItem from './documentItem'
import styles from './documentsList.module.scss'
import Loader from '../../common/loader/loader'
import React from 'react'


class DraftList extends Component {

    componentDidMount() {
        console.log("DocumentsList/DraftList/componentDidMount")
        this.props.getDraftDocuments()
    }

    render() {
        const {
            draftDocuments,
            editDocumentView,
            deleteDocument
        } = this.props
        if (draftDocuments) {
            const draftItems = draftDocuments.map((draft =>
                <DocumentItem
                    key={draft.id}
                    docId={draft.id}
                    editDocumentView={editDocumentView}
                    deleteDocument={deleteDocument}
                    {...draft}
                />))
            return <div className={`${styles.items}`}>
                {draftItems}
            </div>
        } else {
            return <div className={`${styles.documentLoaderWrapper}`}>
                <Loader/>
            </div>
        }

    }
}

export default DraftList
