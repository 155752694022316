import React from 'react';
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {tokenValidate} from '../../../actions'
import PropTypes from 'prop-types';


const withAuth = (WrappedComponent) => {

    class HOC extends React.Component {

        static propTypes = {
            tokenValidate: PropTypes.func.isRequired
        };

        handleVisibilityChange(event) {
            console.log("handleVisibilityChange: ", event)
            if(!document.hidden && !this.props) {
                this.props.tokenValidate()
            }
        }

        mouseEnter() {
            this.props.tokenValidate()
        }

        componentWillMount() {
            this.props.tokenValidate()
        }

        componentDidMount() {
            this.visibilityChangeHandler = (event) => this.handleVisibilityChange(event)
            this.mouseEnterHandler = (event) => this.mouseEnter(event)
            document.addEventListener("visibilitychange", this.visibilityChangeHandler, false);
            document.addEventListener("mouseenter",this.mouseEnterHandler, false);

        }

        componentWillUnmount() {
            document.removeEventListener("visibilitychange", this.visibilityChangeHandler, false);
            document.removeEventListener("mouseenter",this.mouseEnterHandler, false);
        }

        render() {
            return (
                <WrappedComponent {...this.props} />
            );
        }
    }

    const mapStateToProps = () => {
        return {};
    };

    const mapDispatchToProps = dispatch => bindActionCreators({
        tokenValidate: () => dispatch(tokenValidate())
    }, dispatch)

    return connect(mapStateToProps, mapDispatchToProps)(HOC);
};

export default withAuth
