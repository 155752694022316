import gql from 'graphql-tag'

export default gql`
query getDocument($id: String!) {
  getDocument(id: $id) {
  id,
  title,
      template {
      id
      content
      icon
      version
      description
    },
   answers {
    fieldName
    value
   }
  }
}
`
