import React, {Component} from 'react'
import Button from '../../common/button/button'

import styles from './reviewSection.module.scss'
import PropTypes from 'prop-types'

class ReviewSection extends Component {

    render() {
        const {
            goToSection,
            sectionsInErrorTitle
        } = this.props

        const sectionsText = (sectionsInErrorTitle && sectionsInErrorTitle.length > 1) ? 'sections' : 'section'

        return <div className={styles.reviewSection}>
            {sectionsInErrorTitle && sectionsInErrorTitle.length > 0 &&
                <div className={styles.error}>
                    <i className={`material-icons ${styles.error_outline}`}>error_outline</i>
                    <div className={styles.error_text}>
                        <p className={styles.prefix}>The following {`${sectionsText}`} are missing information</p>
                        <ul>
                            {sectionsInErrorTitle.map((section, idx) => {
                                return <li key={idx}><a href='#' onClick={() => goToSection(section.sectionIndx)} >{section.title}</a></li>
                            })}
                        </ul>
                    </div>
                </div>
            }
            <div>
                <p>Lorem ipsum dolor sit amet, etiam iracundia definitiones nec ne, eos fabellas reformidans no, vim eu
                    mucius eirmod nominavi. Eam no dico saepe minimum, nec quem natum apeirian cu. His eu efficiantur
                    definitionem, eam at virtute delenit ceteros, an quo atqui velit insolens. Ne usu erant oratio
                    utamur,
                    at impedit singulis quaestio est. Ea vel inciderint omittantur, eos zril copiosae ea, modo utamur
                    sadipscing his ne. Ut usu perfecto philosophia, te simul mucius per.</p>
                <Button name={'Generate Document'} onClick={() => this.props.reviewDocument()} inverted={true}/>
            </div>
        </div>
    }
}

ReviewSection.propTypes = {
    goToSection: PropTypes.func.isRequired,
    sectionsInErrorTitle: PropTypes.arrayOf(PropTypes.shape({
        sectionIndx: PropTypes.number,
        title: PropTypes.string
    })).isRequired
}


export default ReviewSection
