// import '../sections/Section.css';
import React, {Component} from 'react'
import TextQuestion from './textQuestion'
import TagQuestion from './tagQuestion'
import TextCollection from './textCollection'

import styles from './question.module.scss'
import YesNoQuestion from './yesNoQuestion'
import CountryList from './countryList'
import RadioQuestion from './radioQuestion'
import DurationQuestion from './durationQuestion'
import SliderQuestion from './slider'
import Select from './select'

class Question extends Component {

    questionToRender(type) {
        switch (type) {
            case 'select':
                return <Select {...this.props} />
            case 'duration':
                return <DurationQuestion {...this.props} />
            case 'tag':
                return <TagQuestion {...this.props} />
            case 'slider':
                return <SliderQuestion {...this.props} />
            case 'textCollection':
                return <TextCollection {...this.props} />
            case 'yesNo' :
                return <YesNoQuestion {...this.props}/>
            case 'radio' :
                return <RadioQuestion {...this.props} />
            case 'country':
                return <CountryList {...this.props}/>
            default:
                return <TextQuestion {...this.props} />
        }
    }

    render() {
        const {
            fieldName,
            labelText,
            error,
            type,
            show,
            helper
        } = this.props

        const questionRender = this.questionToRender(type)
        const hideClass = (show) ? '' : 'hide'

        return <div className={`question row ${hideClass} ${styles.question}`} key={fieldName}>
            {show &&
            <div className={`col s12 input-field ${styles.inputField}`}>
                <label htmlFor={fieldName} className="active">{labelText}</label>
                {questionRender}
                <span className="helper-text" data-error={error} data-success="">{helper}</span>
            </div>
            }
        </div>
    }
}

export default Question
