// import './TagQuestion.css';
import React, {Component} from 'react';
import _ from 'lodash';

import style from './yesNoQuestion.module.scss'
// import './YesNoQuestion.css';

class YesNoQuestion extends Component {

    updateQuestion(event) {
        const {
            fieldName,
        } = this.props;

        const targetAnswer = event.target.checked;
        return this.props.updateAnswer(fieldName, [`${targetAnswer}`])
    }


    render() {
        const {
            answers,
            fieldName
        } = this.props;

        const currentAnswer = _.get(answers, `${fieldName}.[0]`);

        const checked = (currentAnswer === 'true') ? 'on' : '';
        return <div className={`switch ${style.toggle}`}>
            <label>
                No
                <input type="checkbox" checked={checked} onChange={(e)=>this.updateQuestion(e)}/>
                <span className={`lever ${style.lever}`} />
                Yes
            </label>
        </div>
    }
}

export default YesNoQuestion;
