import {connect} from 'react-redux'
import DocumentsList from './documentList'

import {draftDocumentsRequest, editDocumentView, deleteDocumentRequest, finalDocumentsRequest} from '../../../actions'
import {documentFinaliseRequest} from '../../../actions/review'

const mapStateToProps = (state) => ({
    draftDocuments: state.documents.drafts,
    finalDocuments: state.documents.final
})

const mapDispatchToProps = dispatch => ({
    getDraftDocuments: () => dispatch(draftDocumentsRequest()),
    getFinalDocuments: () => dispatch(finalDocumentsRequest()),
    editDocumentView: (id) => dispatch(editDocumentView(id)),
    deleteDocument: (id) => dispatch(deleteDocumentRequest(id)),
    documentFinaliseRequest: (id) => dispatch(documentFinaliseRequest(id))
})

const DocumentListContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(DocumentsList)

export default DocumentListContainer
