// import {DRAFT_DOCUMENTS_SUCCESS} from './index'

// Fields
export const UPDATE_ANSWER_REQUEST = 'UPDATE_ANSWER_REQUEST'
export const UPDATE_ANSWER_SUCCESS = 'UPDATE_ANSWER_SUCCESS'
// export const UPDATE_ANSWER_FAILURE = 'UPDATE_ANSWER_FAILURE'
export const ANSWER_UPDATE = 'ANSWER_UPDATE'

export const VALIDATE_ANSWER_REQUEST = 'VALIDATE_ANSWER_REQUEST'
export const INVALID_ANSWER = 'INVALID_ANSWER'
export const INVALID_ANSWER_BACKGROUND = 'INVALID_ANSWER_BACKGROUND'
export const VALID_ANSWER = 'VALID_ANSWER'

export const INVALID_SECTION = 'INVALID_SECTION'
export const VALID_SECTION = 'VALID_SECTION'
export const COMPLETE_SECTION = 'COMPLETE_SECTION'

export const SET_ACTIVE_SECTION = 'SET_ACTIVE_SECTION'

export const QUESTION_VISIBLITY = 'QUESTION_VISIBLITY'

// Navigation
export const GO_TO_SECTION = 'GO_TO_SECTION'

export const VALIDATE_AND_REVIEW_REQUEST = 'VALIDATE_AND_REVIEW_REQUEST'
export const VALIDATE_AND_REVIEW_SUCCESS = 'VALIDATE_AND_REVIEW_SUCCESS'
export const VALIDATE_AND_REVIEW_FAILURE = 'VALIDATE_AND_REVIEW_FAILURE'

// Update title
export const UPDATE_TITLE_REQUEST = 'UPDATE_TITLE_REQUEST'
export const UPDATE_TITLE_SUCCESS = 'UPDATE_TITLE_SUCCESS'
export const UPDATE_TITLE_FAILURE = 'UPDATE_TITLE_FAILURE'

const updateAnswerRequest = (fieldName, value) => ({type: UPDATE_ANSWER_REQUEST, fieldName, value})
const updateAnswerSuccess = (updateAnswer) => ({type: UPDATE_ANSWER_SUCCESS, updateAnswer})
const validateAnswerRequest = (fieldName, value) => ({type: VALIDATE_ANSWER_REQUEST, fieldName, value})


const validateAndReviewRequest = (id) => ({type: VALIDATE_AND_REVIEW_REQUEST, id})
const validateAndReviewSuccess = (id) => ({type: VALIDATE_AND_REVIEW_SUCCESS, id})
const validateAndReviewFailure = (id) => ({type: VALIDATE_AND_REVIEW_FAILURE, id})

// Validation
const invalidAnswer = (fieldName, reason) => ({type: INVALID_ANSWER, fieldName, reason})
const invalidAnswerBackground = (fieldName, reason) => ({type: INVALID_ANSWER_BACKGROUND, fieldName, reason})
const validAnswer = (fieldName) => ({type: VALID_ANSWER, fieldName})

const invalidSection = (sectionIdx) => ({type: INVALID_SECTION, sectionIdx})
const validSection = (sectionIdx) => ({type: VALID_SECTION, sectionIdx})
const completeSection = (sectionIdx) => ({type: COMPLETE_SECTION, sectionIdx})

const answerUpdate = (answers) => ({type: ANSWER_UPDATE, answers})
const goToSection = (sectionIdx) => ({type: GO_TO_SECTION, sectionIdx})

const questionVisibility = (sectionIdx, fieldName, show) => ({type: QUESTION_VISIBLITY, sectionIdx, fieldName, show})


// Update title
const updateTitleRequest = (title) => ({type: UPDATE_TITLE_REQUEST, title})
const updateTitleSuccess = (title) => ({type: UPDATE_TITLE_SUCCESS, title})
const updateTitleFailure = () => ({type: UPDATE_TITLE_FAILURE})

export {
    answerUpdate,
    completeSection,
    goToSection,
    invalidAnswer,
    invalidAnswerBackground,
    invalidSection,
    updateAnswerRequest,
    updateAnswerSuccess,
    validateAnswerRequest,
    validAnswer,
    validSection,
    validateAndReviewRequest,
    validateAndReviewSuccess,
    validateAndReviewFailure,
    updateTitleRequest,
    updateTitleSuccess,
    updateTitleFailure,
    questionVisibility,

}
