
export const DOCUMENT_REVIEW_REQUEST = 'DOCUMENT_REVIEW_REQUEST'
export const DOCUMENT_REVIEW_SUCCESS = 'DOCUMENT_REVIEW_SUCCESS'
export const DOCUMENT_REVIEW_FAILURE = 'DOCUMENT_REVIEW_FAILURE'


export const DOCUMENT_FINALISE_REQUEST = 'DOCUMENT_FINALISE_REQUEST'
export const DOCUMENT_FINALISE_SUCCESS = 'DOCUMENT_FINALISE_SUCCESS'
export const DOCUMENT_FINALISE_FAILURE = 'DOCUMENT_FINALISE_FAILURE'


const reviewDocumentRequest = (id) => ({type: DOCUMENT_REVIEW_REQUEST, id})
const reviewDocumentRequestSuccess = (id, markdown, version, title) => ({type: DOCUMENT_REVIEW_SUCCESS, markdown, version, title})
const reviewDocumentRequestFailure = (id) => ({type: DOCUMENT_REVIEW_FAILURE, id})


const documentFinaliseRequest = (id, version) => ({type: DOCUMENT_FINALISE_REQUEST, id, version})
const documentFinaliseRequestSuccess = (id, version, title) => ({type: DOCUMENT_FINALISE_SUCCESS, id, version})
const documentFinaliseRequestFailure = (id) => ({type: DOCUMENT_FINALISE_FAILURE, id})


export {
    documentFinaliseRequest,
    documentFinaliseRequestSuccess,
    documentFinaliseRequestFailure,
    reviewDocumentRequest,
    reviewDocumentRequestSuccess,
    reviewDocumentRequestFailure
}
