import React, {Component} from 'react'
import {ReactComponent as Pdf} from './file-pdf-box.svg'
import {ReactComponent as Word} from './file-word-box.svg'
import {ReactComponent as Doc} from './file-document-box.svg'

import styles from './downloadButton.module.scss'


export const PDF_TYPE = 'pdf';
export const WORD_TYPE = 'word';
export const MARKDOWN_TYPE = 'doc';

class DownloadButton extends Component {
  iconRender() {
    const {
      type
    } = this.props;

    if(type === PDF_TYPE) {
      return <Pdf/>
    } else if(type === WORD_TYPE) {
      return <Word/>
    } else if(type === MARKDOWN_TYPE) {
      return <Doc/>
    } else {
        throw new Error('Unknown type')
    }

  }

  render() {
    return (
      <button className={`${styles.button}`}
              onClick={()=>this.props.onClick()}
              data-target={this.props.dataTarget}
      ><i className={`${styles.icon}`}>{this.iconRender()}</i>{this.props.name}</button>
    )
  }
}

export default DownloadButton

